@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fade-out {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes fade-in-75 {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 0.75;
    }
}

@keyframes fade-out-75 {
    0% {
        opacity: 0.75;
    }
    100% {
        opacity: 0;
    }
}

@keyframes fade-in-50 {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 0.5;
    }
}

@keyframes fade-out-50 {
    0% {
        opacity: 0.5;
    }
    100% {
        opacity: 0;
    }
}

@keyframes fade-in-25 {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 0.25;
    }
}

@keyframes fade-out-25 {
    0% {
        opacity: 0.25;
    }
    100% {
        opacity: 0;
    }
}

@keyframes make-larger {
    0% {
        transform: scale(0.8);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes make-smaller {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0.8);
    }
}